'use client';

import classNames from 'classnames';
import { FC, ReactNode, useState } from 'react';

// bg-transparent text-dark bg-no-repeat bg-gradient-to-b from-gray-disabled via-transparent to-transparent bg-[length:100%_40%] bg-[image:var(--image-url)] bg-cover bg-left-top

const AppHeader: FC<{ isHome?: boolean; banners?: string[]; children: ReactNode }> = ({ isHome, children, banners }) => {
  const [bannerImg, setBannerImg] = useState(banners?.at(0));

  return (
    // @ts-expect-error
    <header style={{ '--image-url': `url(${bannerImg})` }} className={classNames({ 'h-[80vh] bg-cover bg-left-top bg-[image:var(--image-url)] ': isHome }, 'p-4 ')}>
      {children}
    </header>
  );
};

export default AppHeader;
