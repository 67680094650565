import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import React, { FC } from "react";


type BrandLogoProps = {
  src?: string;
  className?: string;
};

const BrandLogo: FC<BrandLogoProps> = ({ src = "/logo-crayon.png", className }) => {
  return (
    <Link href="/" className={classNames(className, "flex items- justify-")}>
      <div className="py-2 md:py-5 w-[100px] md:w-[100px]">
        <Image width={250} height={150} className="w-full" src={src} alt="Brand Logo" priority/>
      </div>
    </Link>
  );
};

export default BrandLogo;
