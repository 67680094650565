export class ShopService {
  private baseUrl: string;
  private url: string = "";
  private page = 1;
  private limit = 50;
  private data: any | null = null;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  protected handleError(error: never) {
    console.error({baseURL: this.baseUrl, url: this.url, error: (error as Error).message, stacks: (error as Error).stack});
  }

  protected async exec() {
    try {
      const response = await fetch(this.url);
      if (!response.ok) this.handleError(new Error(response.statusText) as never);
      const responseData = await response.json() as { data: unknown };
      return responseData?.data
    } catch (error) {
      this.handleError(error as never);
    }
  }

  setPage(page: number) {
    this.page = page;
    return this;
  }

  setLimit(limit: number) {
    this.limit = limit;
    return this;
  }

  canGoNext = () => Array.isArray(this.data) && (this.data as any[]).length === this.limit
  canGoPrev = () => this.page > 1

  nextPage = () => this.canGoNext() && this.setPage(this.page + 1);
  prevPage = () => this.canGoPrev() && this.setPage(this.page - 1);

  async getLines() {
    this.url = `${this.baseUrl}/api/lines?limit=${this.limit}&page=${this.page}`;
    this.data = await this.exec() as any[] || null;
    return this.data
  }

  async getProductsByCategory(category?: string) {
    this.url = `${this.baseUrl}/api/products?joins=[category,line,collection]&limit=${this.limit}&page=${this.page}`;
    if (category) this.url += `&filters=[category:name:${category},status:publicado]`;
    else this.url += `&filters=[status:publicado]`;
    ;
    this.data = await this.exec() as any[] || null;
    return this.data
  }

  async getProductsByLine(line: string = "") {
    this.url = `${this.baseUrl}/api/products?joins=[line]&filters=[line:name:${line},status:publicado]&limit=${this.limit}&page=${this.page}`;
    this.data = await this.exec() as any[] || null;
    return this.data
  }

  async getProductById(id: string) {
    this.url = `${this.baseUrl}/api/products/${id}?joins=[line,collection]`;
    return await this.exec() as any
  }

  async getProductByName(name: string) {
    if (!name) return null
    this.url = `${this.baseUrl}/api/products?filters=[name:${name},status:publicado]&limit=1`;
    const items = await this.exec() as any[]
    return items?.at(0) || null
  }

  getUserPrice(product: any, user: any) {
    if (!product) return 0
    const price = product.price || {}
    if (user?.role === "MAYORISTA") return price?.mayor?.value || 0
    if (user?.role === "FRANQUICIA") return price?.franchise?.value || 0
    return price?.public?.value || 0
  }

  getFormattedPrice(product: any, user: any) {
    const price = this.getUserPrice(product, user)
    return this.formatPrice(price)
  }

  formatPrice(price: number) {
    if (!price) return 0
    return new Intl.NumberFormat("es-AR", {style: "currency", currency: "ARS"}).format(price)
  }
}
